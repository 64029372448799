<template>
  <div class="category-container">
    <section>
      <div class="container-fluid">
        <h1 class="header">
          {{ title }}
        </h1>
      </div>

      <div
        v-show="!is_loading"
        class="cards-container"
        :class="item_image_shape"
      >
        <div v-for="(item, index) in items" :key="item.id">
          <carousel-item
            :item="item"
            :item-type="item_image_shape"
            :position="index"
          />
        </div>
      </div>
    </section>

    <div v-show="is_loading" class="loader-container">
      <loader />
    </div>

    <div v-if="!is_loading && items.length < 1" class="loader-container">
      <p>No items left</p>
    </div>
  </div>
</template>

<script>
  import CarouselItem from '@/components/swiper-carousel/carousel-item/carousel-item';
  import WishlistService from '@/store/wishlist';
  import Loader from '@/components/loader/loader';

  export default {
    name: 'WishlistPagePage',
    components: {
      CarouselItem,
      Loader,
    },
    data() {
      return {
        pagination: false,
        page: 1,
        paginations_count: 2,
        is_loading: false,
        add_new_pagination_page: true,
        default_link: '',
        title: 'Wishlist',
        item_image_shape: 'vertical',
        aspect_ratio: '1x1',
      };
    },
    computed: {
      items() {
        return WishlistService.getListArray();
      },
    },
    mounted() {
      this.$stats.send('page_opened', {
        page_type: 'wishlist',
      });
    },
    methods: {
      calculateWidth() {
        this.windowSize = window.innerWidth;
      },
    },
  };
</script>
<style scoped lang="scss">
  .header {
    margin-top: 30px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  .category-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, max-content));
    justify-content: center;
    gap: 16px;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;

    &.vertical {
      grid-template-columns: repeat(auto-fit, minmax(159px, max-content));
    }

    &.circle {
      grid-template-columns: repeat(auto-fit, minmax(135px, max-content));
    }

    &.horizontal {
      grid-template-columns: repeat(auto-fit, minmax(260px, max-content));
    }
  }

  .pagination-container {
    max-width: 500px;
    margin: 0 auto 45px auto;
  }

  .loader-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
